.right {
  background-color: #f4f4f8;
  float: right;
}
.left {
  background-color: #dee2e6;
  float: left;
}
.author {
  background-color: #fff0b3;
}

/* CSS talk bubble */
.talk-bubble {
  margin: 20px 30px;
  display: inline-block;
  position: relative;
  width: 320px;
  height: auto;
  position: relative;
}

.tri-right.left-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -10px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 10px solid;
  border-color: #dee2e6 transparent transparent transparent;
}
.tri-right.right-top.author:after {
  border-color: #fff0b3 transparent transparent transparent;
}
.tri-right.right-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -10px;
  top: 0px;
  bottom: auto;
  border: 10px solid;
  border-color: #f4f4f8 transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  white-space: pre-line;
  word-break: break-word;
}
.notification-left {
  position: absolute;
  left: -24px;
}
.notification-right {
  position: absolute;
  right: -24px;
}
