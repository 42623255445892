/* square buttons */
.rec.rec-arrow {
  border-radius: 4px;
  width: 35px;
  height: 35px;
  min-width: 35px;
  line-height: 35px;
  background-color: #fff;
  font-size: 20px;
  box-shadow: 0 0 2px 0px #FFC20F;
}

.rec.rec-arrow:focus {
  background-color: #fff !important;
  color: inherit !important;
}

.rec.rec-arrow:hover {
  background-color: #FFC20F !important;
  color: #fff !important;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  opacity: .5;
}
