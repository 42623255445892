.table {
  font-size: 0.8rem;
  tr {
    height: 40px;
    :last-child {
      td {
        border-bottom: 0;
      }
    }
    &:nth-of-type(even) {
      background-color: #f1f1f1;
      td {
        background-color: #f1f1f1;
      }
    }
  }

  th,
  td {
    padding: 8px;
    overflow: hidden;
    white-space: nowrap;

    :last-child {
      border-right: 0;
    }
  }

  td {
    display: inline;
    border-bottom: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    background-color: #fff;

    text-overflow: ellipsis;
  }

  th {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    background-color: #dcdcdc;
  }

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 4;
      width: fit-content;
    }

    .header {
      top: 0;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #eaeaea;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #eaeaea;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #eaeaea;
    }
  }
}
