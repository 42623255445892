.table.actions {
  tbody {
    tr {
      height: 60px;
      td {
        display: flex !important;
        align-items: center;
      }
    }
  }
}
