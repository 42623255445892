.legend-main {
  font-weight: bold;
  font-size: 170%;
  color: #191714;
}

.legend-sub {
  font-weight: normal;
  font-size: 60%;
  color: #191714;
}

.legend {
  font-weight: bold;
  color: #191714;
}