.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.multi-select {
  z-index: 2;
  width: 100%;
}

.toolbarClassName {
  border: 0 !important;
  padding: 0 !important;
  justify-content: flex-end !important;
}

.editorClassName {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 200px !important;
  padding: 0 8px;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
